<template>
  <div class="app-container">
    <div id="qrcode"></div>
    <remote-js :jsUrl="jsUrl" :js-load-call-back="loadRongJs"></remote-js>
  </div>
</template>

<script>
import {web_inter} from '@/untils/common';
import RemoteJs from './wchartjs'
export default {
  components: { RemoteJs },
  data(){
    return{
      jsUrl:`https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js`
    }
  },
    mounted() { // 注意调用方法生命周期不能为created，否则不会加载出来
        // console.log(encodeURIComponent(`http://${window.location.host}/wxlogin`));
        // this.loadRongJs();
    },
    methods: {
      loadRongJs() {
        var obj = new WxLogin({
          // 是否开启新页面展示登录二维码
          self_redirect: false,
          // html中要展示二维码的标签id
          id: "qrcode", 
          // 网站应用的AppID
          appid: web_inter.wchart.client_id, 
          scope: "snsapi_login",
          // 回调地址，注意一定要在微信平台下的授权回调域域名下，否则会报redirect_uri错误
          // 且要用encodeURIComponent()方法转码
          // 在此我采用的是自己配置一个wxlogin路由页面做中间页面用于请求后端及结果处理
          redirect_uri: encodeURIComponent(`${window.location.origin}/wchartlogin`), 
          // state参数用于二次检验，可自行设置随机字符串等
          state: Math.ceil(Math.random() * 1000),
          style: "black",
        });
        console.log(obj)
      },
    },
};
</script>

<style scoped>
.app-container {
  height: 800px;
  position: relative;
}
#qrcode {
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 100;
  /* top: 50%;
  right: 8%;
  transform: translateY(-50%); */
  padding: 30px 20px;
}
</style>
